#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 20px;
    border-top: 3px solid rgba(82, 85, 86, 0.692);
    box-sizing: border-box;
    height: 40vh;
    width: 80%;
    color: white;
    font-weight: 400;
    display: flex;
    justify-content: center;
    font-size: 1rem;
}

.contactContainer {
    width: 350px;
    font-size: 25px;
    height: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.email {
    border: 1px solid gray;
    border-radius: 5px;
    width: 300px;
    height: 15%;
    gap: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.vl {
    border-left: 1px solid gray;
    height: 100%;
}

.contactItem {
    border-radius: 5px;
    text-align: center;
    background: rgba(52, 52, 61, 0.757);
    color: white;
    padding: 8px;
    width: 50px;
}

.contactItem:hover {
    background: -webkit-linear-gradient(45deg, #65adaf, #77d7ec);
    transform: scale(1.02)
}
.email:hover {
    background: -webkit-linear-gradient(45deg, #65adaf, #77d7ec);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.copyright {
    margin-top: 20px;
    color: gray;
    font-size: 0.9rem;
}
.crYear {
    color: rgba(177, 78, 78, 0.801);
}

