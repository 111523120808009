
*,
:after,
:before {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.App {
  box-sizing: border-box;
  background-image: linear-gradient(30deg, rgba(30, 30, 48), rgb(6, 5, 16), rgb(27, 24, 32));
  color: beige;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*
  width: 100vw;*/
}


