#about {
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    line-height: 30px;
}

.resumeContainer a {
    text-decoration: none;
    color: white;
}

.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 99%;
}

#about .dock {
    min-width: 750px;
    height: 8vh;
    border: 1px solid rgb(84, 83, 83);
    border-radius: 15px;
    background-image: linear-gradient(rgba(112, 128, 144, 0.308), rgba(235, 228, 230, 0.1));
    display: flex;
    align-items: center;
    padding: 13px;
    gap: 15px;
    box-shadow: 0 1px 1px rgba(133, 131, 131, 0.12),
        0 2px 2px rgba(82, 80, 80, 0.5),
        0 4px 4px rgba(143, 141, 141, 0.12),
        0 8px 8px rgba(83, 83, 83, 0.11),
        0 16px 16px rgba(87, 86, 86, 0.158);
}


#about .iconName {
    position: absolute;
    bottom: 40px;
    left: 7px;
    opacity: 0;
    font-size: 13px;
}

#about .iconContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

#about .dockIcon {
    width: 42px;
    height: 40px;
}

#about .dockIcon:hover {
    transform: scale(1.25);
}

#about .dockIcon:hover .iconName {
    opacity: 0.75;
    font-size: 10px;
    transform: none;
}

.dockIcon img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.typeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 13vh;
    width: 80%;
    padding-bottom: 40px;
}

.typeIt {
    height: 10vh;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.typeIt a {
    font-weight: bolder;
    background: -webkit-linear-gradient(45deg, #65adaf, #77d7ec, #5572c0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.profile {
    max-width: 650px;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 1.1rem;
    font-weight: 200;
    gap: 10px;
    color: rgba(235, 228, 230, 0.75);
}

.resumeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    max-width: 650px;
    width: 70%;
    height: 50%;
    margin-bottom: 5%;
}

.resume {
    border: 1px solid rgba(235, 228, 230, 0.777);
    border-radius: 10px;
    padding: 10px;
}

.resume:hover {
    border: 1px solid #77d7ec;
    background: -webkit-linear-gradient(45deg, #65adaf, #77d7ec, #5572c0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.15s;
    transform: scale(1.02);
}

.profile .underline {
    font-weight: 500;
}


@media screen and (max-width: 600px) {
    .dock, .dockIcon img  {
        width: 0%;
        display: none;
        border: none;
        box-shadow: none;
        opacity: 0;
    }
}
@media screen and (max-width: 450px) {
    #about {
        padding-top: 30vh;
    }
    .typeContainer {
        gap: 20vh;
    }
    .profile {
        font-size: 1rem;
    }
}
