
#timeline {
    height: 100%;
    width: 99%;
    padding-top: 70px;
}

@media screen and (max-width: 600px) {
    #timeline {
        margin-top: 1100px;
        height: 1100px;
    }
    #timeline .title {
        margin-bottom: 40px;
    }
}

#timeline .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 60%;
    height: 800px;
}
#timeline .title {
    font-size: 25px;
    font-weight: 600;
    font-family: sans-serif;
    text-shadow: 0 0 5px rgb(168, 163, 163);
    -webkit-box-shadow: 0 8px 6px -6px rgb(182, 176, 176);
    -moz-box-shadow: 0 8px 6px -6px rgb(204, 194, 194);
    box-shadow: 0 8px 6px -6px rgb(190, 188, 188);
  }
#timeline .period {
    font-size: 13px;
    color: gray;
}
.smaller {
    font-size: 12px;
}

#timeline .content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
#timeline .innerContainer {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
}

#timeline .subTitle {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
    font-size: 20px;
    font-weight: 400;
}

#timeline .icon {
    background-color: rgb(246, 246, 233);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#timeline .iconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    height: 120px;
    min-height: 120px;
}
.timelineBar {
    align-items: center;
    background-color: rgb(177, 216, 243);
    height: 7vh;
    width: 1px;
}

#timeline i {
    font-size: 15px;
    color: black;
}
#timeline .smaller {
    text-decoration: underline;
    text-decoration-color: beige;
}
#timeline p {
    color: rgba(235, 228, 230, 0.75);
    font-size: 15px;
    font-weight: 200;
}

@media screen and (max-width: 450px) {
    #timeline .container {
        width: 90%;
    }
    .innerContainer {
        height: 20vh;
    }
    #timeline .content {
        gap: 2vh;
    }
    #timeline .subTitle {
        font-size: 18px;
        font-weight: 500;
    }
    #timeline p {
        font-size: 0.8rem;
    }
    .timelineBar {
        height: 25%;
        width: 1px;
    }
    #timeline .iconContainer {
        gap: 7px;
        height: 50vh;
    }
    .last {
        height: 8vh;
    }
  }
  