#project {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  width: 100%;
  width: 99%;
  font-weight: bolder;
  align-items: center;
  padding-top: 100px;
}

#project .title {
  font-size: 25px;
  font-weight: 600;
  font-family: sans-serif;
    text-shadow: 0 0 5px rgb(168, 163, 163);
    -webkit-box-shadow: 0 8px 6px -6px rgb(182, 176, 176);
    -moz-box-shadow: 0 8px 6px -6px rgb(204, 194, 194);
    box-shadow: 0 8px 6px -6px rgb(190, 188, 188);
}

.project-grids {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
  gap: 20px;
}

@media screen and (max-width: 850px) {
  .project-grids {
    grid-template-columns: auto auto;
    
  }

  #project {
    height: 1100px;
  }
}

@media screen and (max-width: 600px) {
  .project-grids {
    grid-template-columns: auto;
  }
}


.project-grids .grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(6, 5, 16, 0.952);
  position: relative;
  height: 300px;
  width: 300px;
  border-radius: 5px;
  box-shadow:
    1px 1px 2px 2px hsla(0, 2%, 83%, 0.075),
    0 4px 4px hsla(0, 3%, 71%, 0.075),
    0 6px 6px hsla(0, 2%, 77%, 0.05),
    0 8px 8px hsla(0, 4%, 69%, 0.05),
    0 12px 12px hsla(0, 8%, 90%, 0.05);
  border: 1px solid rgba(169, 169, 169, 0.356);
  color: beige;
}

.project-grids .grid:hover .hideTop {
  background-color: rgba(6, 5, 16, 0.952);
  display: inline-block;
  opacity: 1;
  animation: slideDown 1s ease;
  font-size: 18px;
}

.project-grids .grid:hover .hideBot {
  background-color: rgba(6, 5, 16, 0.952);
  display: inline-block;
  opacity: 1;
  animation: slideUp 1s ease;
  font-weight: 300;
  font-size: 18px;
  background: transparent;
}

.project-grids .grid:hover .imgmobile {
  opacity: 0.05;
}

.project-grids .imgmobile:hover {
  opacity: 0;
}

.imgzoom:hover {
  opacity: 0;
}

.project-grids .grid:hover .imgzoom {
  opacity: 0.05;
}

.imgmobile {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: .3s ease;
}

.imgzoom {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right top;
  transition: .3s ease;
}

.hideTop {
  position: absolute;
  height: 20%;
  width: 100%;
  top: 10%;
  display: block;
  opacity: 0;
  font-weight: 400;
}

.hideBot {
  position: absolute;
  height: 20%;
  width: 100%;
  bottom: 15%;
  display: flex;
  opacity: 0;
}

.hideSmall {
  font-size: 15px;
  font-weight: 200;
}

.hideBot div {
  border: 1px solid beige;
  border-radius: 5px;
  width: 150px;
  height: 40px;
  margin: auto;
  padding-top: 7px;
}

.demo {
  text-decoration: none;
  color: beige;
}

.demo:hover div {
  color: rgb(72, 184, 228);
  border: 1px solid rgb(72, 184, 228);
}

.title {
  top: 0;
  font-size: 30px;
  font-weight: 450;
}


@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}